export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'AboutCompany',
            title: 'КЕЛ в цифрах',
            hash: '#company-in-numbers'
        },
        {
            id: 2,
            name: 'AboutCompany',
            title: 'Услуги',
            hash: '#services'
        },
        {
            id: 3,
            name: 'AboutCompany',
            title: 'Преимущества',
            hash: '#our-advantages'
        },
    ]
}


