<template>
    <div>
        <vue-title title="О компании | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes"/>
        <div id="about-company" class="container pt-4">
            <div class="text-center mb-3">
                <h1 class="mb-4 page-title">О компании</h1>
            </div>
            <div class="row">
                <div class="col-12 col-lg-5 pr-3 pr-lg-0">
                    <div class="bg-white h-100 p-4">
                        <p><strong class="text-primary">КЕЛ</strong> - cервисно-логистическая компания, призванная объединить интересы участников рынка автомобильных перевозок РК -  грузоотправителей, грузоперевозчиков, грузополучателей и сервисных компаний</p>
                        <h4 class="text-primary text-uppercase mt-5">Наша миссия</h4>
                        <p>Увеличение транзитного потенциала и развитие отрасли автомобильных перевозок Республики Казахстан</p>
                        <h4 class="text-primary text-uppercase mt-5">Дата основания компании</h4>
                        <p>январь 2016 года</p>
                    </div>
                </div>
                <div class="col-12 col-lg-7 pl-3 pl-lg-0">
                    <div class="bg-white h-100 d-flex align-items-center">
                        <img src="@/assets/images/about-company/1.jpeg" class="w-100">
                    </div>
                </div>
            </div>
        </div>
        <div id="company-in-numbers" class="company-in-numbers">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title">Компания в цифрах</div>
                    </div>
                </div>
            </div>
            <div class="bg-white py-4">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-7">
                            <div class="media mb-3">
                                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 img-48" alt="7 лет на рынке международных грузовых автомобильных перевозок">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1">7 лет</h3>
                                    <p class="text-primary font-18">на рынке международных грузовых автомобильных перевозок</p>
                                </div>
                            </div>
                            <div class="media mb-3">
                                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 img-48" alt="Наша география страны  Европы, СНГ, Турция, Китай, перевозки  по всему Казахстану">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1">Наша география</h3>
                                    <p class="text-primary font-18">страны Европы, СНГ, Турция, Китай, перевозки по всему Казахстану</p>
                                </div>
                            </div>
                            <div class="media mb-3">
                                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 img-48" alt="Свыше 100 Партнеров доверяют нам услуги грузоперевозок">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1">Свыше 100 Партнеров</h3>
                                    <p class="text-primary font-18">доверяют нам услуги грузоперевозок</p>
                                </div>
                            </div>
                            <div class="media mb-3">
                                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 img-48" alt="Свыше 12 000 грузоперевозок мы осуществили">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1">Свыше 12 000 грузоперевозок</h3>
                                    <p class="text-primary font-18">мы осуществили</p>
                                </div>
                            </div>
                            <div class="media">
                                <img src="@/assets/images/company-in-numbers/confirmed.png" class="align-self-start mr-3 img-48" alt="Свыше 250 000 тонн различных грузов мы перевезли">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-34 mb-1">Свыше 250 000 тонн</h3>
                                    <p class="text-primary font-18">различных грузов мы перевезли</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-5">
                            <img src="@/assets/images/company-in-numbers/volume-sales.png" class="img-fluid mx-auto d-block mt-3" alt="Объем продаж за 2020 год составил 2,3 млрд тенге">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="services" class="services">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="title">Услуги</div>
                    </div>
                </div>
            </div>
            <div class="bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-5 pr-3 pr-lg-0 mt-0 mt-md-4">
                            <div class="media my-5 ml-4">
                                <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="Все виды логистических услуг">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-32 mb-1">Все виды<br>логистических услуг</h3>
                                </div>
                            </div>
                            <div class="media mb-5 pt-3 ml-4">
                                <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="Все виды транспорта">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-32 mb-1">Все виды транспорта</h3>
                                </div>
                            </div>
                            <div class="media mb-5 pt-3 ml-4">
                                <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="Весь мир">
                                <div class="media-body">
                                    <h3 class="mt-0 text-primary font-28 font-md-32 mb-1">Весь мир</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7 pl-3 pl-lg-0">
                            <div class="bg-white h-100 d-flex align-items-center">
                                <img src="@/assets/images/about-company/services.jpeg" class="w-100" alt="Услуги">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="our-advantages" class="our-advantages">
            <div class="pt-5 pb-3">
                <div class="container">
                    <div class="d-flex flex-column flex-md-row justify-content-between align-items-left align-items-md-center">
                        <div class="title">Наши преимущества</div>
                        <div class="total d-block"><span class="text-primary font-22">8 000</span> ЕДИНИЦ АВТОТРАНСПОРТА</div>
                    </div>
                </div>
            </div>
            <div class="bg-white pt-4 pb-4">
                <div class="container">
                    <div class="card-deck">
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-1.png" class="card-img-top" alt="ПРЯМЫЕ АВТО">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="ПРЯМЫЕ АВТО">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">ПРЯМЫЕ<br>АВТО</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-2.png" class="card-img-top" alt="ПРОВЕРЕННЫЕ АВТО">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="ПРОВЕРЕННЫЕ АВТО">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">ПРОВЕРЕННЫЕ<br>АВТО</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-3.png" class="card-img-top" alt="СЛЕЖЕНИЕ ГРУЗОВ">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="СЛЕЖЕНИЕ ГРУЗОВ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">СЛЕЖЕНИЕ<br>ГРУЗОВ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-deck">
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-4.png" class="card-img-top" alt="ПРЯМЫЕ АВТО">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="СТРАХОВОЕ ПОКРЫТИЕ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">СТРАХОВОЕ<br>ПОКРЫТИЕ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-5.png" class="card-img-top" alt="ПРОВЕРЕННЫЕ АВТО">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="ИНДИВИДУАЛЬНЫЙ ПОДХОД">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">ИНДИВИДУАЛЬНЫЙ<br>ПОДХОД</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <img src="@/assets/images/about-company/our-advantages-6.png" class="card-img-top" alt="СЛЕЖЕНИЕ ГРУЗОВ">
                            <div class="card-body">
                                <div class="media mt-3">
                                    <img src="@/assets/images/about-company/confirmed.png" class="align-self-start mr-3 img-48" alt="ПРОЗРАЧНОСТЬ В БИЗНЕСЕ">
                                    <div class="media-body">
                                        <h3 class="mt-0 text-primary font-28">ПРОЗРАЧНОСТЬ<br>В БИЗНЕСЕ</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <h3 class="text-primary mt-3">Insurance is provided by KEL LLP and is reinsured (95%) by the Chubb Group</h3>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import {Header, Footer} from '../../shared/components';
    import headerRoutes from './header-routes';

    export default {
        name: "AboutCompany",
        components: {
            Header,
            Footer
        },
        data() {
            return {
                headerRoutes
            }
        }
    }
</script>

<style lang="scss" scoped>
    .services, .our-advantages, .company-in-numbers {
        .title {
            font-size: var(--size-26);
        }

        .img-48 {
            width: 48px;
        }
    }
</style>
